import axios from "axios";
import qs from 'qs'
import Vue from "vue";
import store from "@/store";
// import router from "@/router";


const instance = axios.create({
	// 打包用
	// timeout: 10000,
	baseURL: process.env.VUE_APP_SERVER_URL,
	headers: {
		"Content-Type": "application/json;charset=utf-8",
	},
});

// 开发环境
if (process.env.NODE_ENV === 'development') {
	Vue.prototype.$pre = 'http://192.168.0.116:7002'
	// if (window) {
	//     window.console.log = function () {};
	// }
}
// 生产环境 
if (process.env.NODE_ENV === 'production') {
	Vue.prototype.$pre = 'http://labor.ibstd.cn'
}
// 测试环境 
if (process.env.NODE_ENV === 'test') {
	Vue.prototype.$pre = 'http://192.168.0.116:7002'
}

// Excel导出拦截
const instanceExp = axios.create({
	headers: {
		"Content-Type": "application/json;charset=utf-8;",
	},
	responseType: 'blob'
});
// 添加请求拦截器
instance.interceptors.request.use(config => {
	// 访问的不是登录需要加token
	if (config.url !== '/inside/login') {
		config.headers.authorization = store.state.user.token
	}
	return config
})

// 响应拦截器
instance.interceptors.response.use((res) => {
	console.log("请求地址" + res.config.url);
	console.log(res);

	// 掉线统一处理
	// if(res.data.msg === '登录已过期或访问权限受限') {
	//     router.push('/login')
	// }
	return res
})

// get请求
export const get = (url, params = {}) => instance.get(url, {
	params
})
export const getExp = (url, params = {}) => instanceExp.get(url, {
	params
})
// delete请求
export function deletefn(url, params) {
	return new Promise((resolve, reject) => {
		instance.delete(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}

// post请求
export const post = (url, params = {}, isFile = false) => {
	let data = null;
	if (isFile) {
		data = new FormData()
		for (let k in params) {
			data.append(k, params[k])
		}
	} else {
		// JSON
		data = JSON.stringify(params)
	}
	return instance.post(url, data)
}
export const post2 = (url, params = {}) => {
	let data = null;

	data = new FormData()
	for (let k in params) {
		data.append(k, params[k])
	}
	return instance.post(url, data, {
		headers: {
			"Content-Type": "multipart/form-data;"
		}
	});
}
// post1请求
export function post1(url, params) {
	return new Promise((resolve, reject) => {
		instance.post(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
// 导出excel
export const postExcel = (url, params = {}, isFile = false) => {
	let data = null;
	if (isFile) {
		data = new FormData()
		for (let k in params) {
			data.append(k, params[k])
		}
	} else {
		// JSON
		data = qs.stringify(params)
	}
	return instanceExp.post(url, data)
}


//put 请求
export function put(url, params) {
	return new Promise((resolve, reject) => {
		instance.put(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}