import {
    reqOuthNameEd,
    reqOutperCount,
    reqOutperL,
    saveExistManyGroupMan, // 根据项目、身份证号
    reqOutSerchNameEd, // 根据人员姓名
} from '../../request/api'
// 状态
const state = {
    allList: [],
    search: [],
    list: [],
    allPer: [], // 所有人 
    // allPer:[], 
    // 总个数
    total: 0,
    // 一页显示的个数
    limit: 10,
    // 当前页码值
    page: 1,
    key: "",
    org_id: 1
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
    },
    // getTotal(state, total) {
    //     state.total = total
    // },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
    },
    getAllList(state, arr) {
        state.allList = arr
    },
    changeSeaAll(state, arr) {
        state.allPer = arr
    },
    changeSearch(state, key) { // 将对比后的数组列表存放在search中
        // if (key) { // 如果有关键字，则做以下操作
        state.search = [];
        state.key = key;
        // state.allList.forEach(v => {
        //     if (v.per_name.includes(key)) { // 包含关键字（模糊查询）
        //         state.search.push(v)
        //     }
        // })
        // state.list = state.search.slice(0, state.limit); // 将第一页展示10条信息
        // state.total = state.search.length; // 做分页的总条数
        // state.page = 1
        // } else { // 否则将search清空
        //     state.search = [];
        // }
    },
    changeOrg(state, org_id) {
        state.org_id = org_id
    },
}
// 异步和逻辑操作
const actions = {
    reqAllList({
        commit
    }) {
        reqOutperL(JSON.parse(sessionStorage.getItem("user")).org[0].org_id).then(res => {
            commit('getAllList', res.data.data.items)
        })
    },
    reqList({
        commit,
        dispatch,
        state: {
            page,
            limit,
            search,
            org_id
        }
    }, orgId) {
        commit('changeOrg', orgId ? orgId : org_id)
        if (search.length == 0) {
            // console.log(search)
            reqOuthNameEd(page, limit, orgId ? orgId : org_id).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
            }).catch(error => {
                // errorAlert("请检查网络")
            })

        } else {
            page--;
            let a = search.slice(page * state.limit, (page * state.limit) + state.limit);
            commit('changeList', a)
        }
    },
    reqTotal({
        commit,
        dispatch,
        state: {
            page,
            limit,
            search
        }
    }, orgId) {
        reqOuthNameEd(page, limit, orgId).then(res => {
            commit('changeTotal', res.data.data.pageModel.total)
        })
    },
    changePage({
        commit,
        dispatch,
        state: {
            key
        }
    }, num ) {
        // num当前页码值 page=num
        if (key !== "") {
            commit('changePage', num)
            dispatch('changeSeaPer', key)
        } else {
            commit('changePage', num)
            // 发请求
            dispatch('reqList')
        }
    },
    // 搜索框(姓名)
    changeSearch({
        commit,
        dispatch,
        state: {
            page,
            limit,
            org_id
        }
    }, key) {
        console.log(key, 'guanjianzi');
        if (key) { // 关键字存在时做以下操作
            commit('changeSearch', key)
            reqOutSerchNameEd(1,
                limit,
                key.keywords, // 姓名  
                org_id,
            ).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('changeSearch')
                    return
                }
                commit('changeList', list)
            })
        } else { // 不存在时（由于action不能直接操作state的值）
            commit('changeSearch', key)
            // 重新请求列表及总数
            dispatch('reqList')
            dispatch('reqTotal')
        }
    },
    // 搜索框(项目)
    changeSearchPro({
        commit,
        dispatch,
        state: {
            page,
            limit,
        }
    }, key) {
        if (key) {
            saveExistManyGroupMan(
                page,
                limit,
                JSON.parse(sessionStorage.getItem("user")).org[0].org_id,
                key.keywords, // 身份证号
                key.keyPro,
                key.banName,
                key.tof,
                key.phone
            ).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
            })
        } else { // 不存在时（由于action不能直接操作state的值）
            commit('changeSearch', key)
            // 重新请求列表及总数
            dispatch('reqList')
            dispatch('reqTotal')
        }
    },
    reqAllPer({
        commit,
        state: {
            page,
            limit,
            search,
            org_id
        }
    }, orgId) {
        if (search.length == 0) {
            reqOuthNameEd(page, limit, orgId?orgId:org_id).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqAllPer')
                    return
                }
                commit('changeSeaAll', list)
            })
        } else {
            page--;
            let a = search.slice(page * state.limit, (page * state.limit) + state.limit);
            commit('changeList', a)
        }
    },
    changeSeaPer({
        commit,
        dispatch,
        state: {
            page,
            limit,
            org_id
        }
    }, key) {
        // console.log(orgId);
        if (key) { // 关键字存在时做以下操作
            commit('changeSearch', key)
            // console.log(key);
            reqOutSerchNameEd(page, limit, key, org_id).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                // console.log(res.data.data.pageModel.records);

                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    // dispatch('reqAllPer')
                    dispatch('reqList')
                    dispatch('reqTotal')
                    return
                }
                commit('changeList', list)
            })
        } else { // 不存在时（由于action不能直接操作state的值）
            commit('changeSearch', key)
            // 重新请求列表及总数
            // dispatch('reqAllPer')
            dispatch('reqList')

            dispatch('reqTotal')
        }
    },
}
// 导出数据
const getters = {
    allList(state) {
        return state.allList
    },
    list(state) {
        return state.list
    },
    allPer(state) {
        return state.allPer
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.limit
    },
    page(state) {
        return state.page
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}