import Vue from 'vue'
let vm = new Vue()
export const successAlert = (msg) => {
    vm.$message({
        message: msg,
        type: 'success'
    })
}
export const infoAlert = (msg) => {
    vm.$message({
        message: msg,
        type: 'info'
    })
}
export const errorAlert = (msg) => {
    vm.$message({
        dangerouslyUseHTMLString: true,
        message: msg,
        type: 'warning'
    })
}