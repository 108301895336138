import {
    reqAttenList,
    reqPsdOrgId
} from '../../request/api'
import {
    errorAlert
} from '@/utils/alert'
// 状态
const state = {
    // 初始值
    list: [],
    page: 1,
    total: 0,
    limit: 10,
    search: [],
    loading: false,
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
        state.loading = false
    },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
        state.loading = true
    },
    changeSearch(state, key) {
        state.page = 1
        state.search = key
        state.loading = true
    }
}
// 异步和逻辑操作
const actions = {
    // 请求列表
    reqList({
        commit,
        dispatch,
        state: {
            page,
            limit,
            search
        }
    }, key) {
        // 发请求
        reqPsdOrgId(JSON.parse(sessionStorage.getItem("user")).org[0].org_id).then(res => {
            reqAttenList({
                page,
                limit,
                follow: search ? search.follow : "",
                arrive: search ? search.arrive : "",
                user_name: search ? search.user_name : "",
                orgId: res.data,
                projectId: search ? search.keyPro : "",
                deviceLdid: search ? search.keyEqu : "",
            }).then(res => {
                const list = res.data.data.items ? res.data.data.items : [];
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                if (list.length == 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
                commit('changeTotal', Number(res.data.data.total))
            }).catch(err => {
                console.log(err);
                errorAlert("服务器请求错误");
            });
        })
    },
    changePage({
        commit,
        dispatch,
    }, num) {
        commit('changePage', num)
        dispatch('reqList')
    },
    changeSearch({
        commit,
        dispatch,
    }, key) {
        commit("changeSearch", key)
        dispatch('reqList')
    },
}
// getters 导出数据
const getters = {
    list(state) {
        return state.list
    },
    loading(state) {
        return state.loading
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.limit
    },
    page(state) {
        return state.page
    },
    // date(state) {
    //     return state.date
    // },
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}