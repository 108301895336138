const actions = {
    changeUser({ commit }, user) {
        // 调用mutation改user
        commit('changeUser', user)
        // 本地存储
        if(user.token) {
            // 有token表示本次是登陆
            sessionStorage.setItem('user', JSON.stringify(user))
        } else {
            sessionStorage.removeItem('user')
        }
    }
}

export default actions