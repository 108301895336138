import {
    reqOutperList,
    reqOutperCount,
    reqOutperL,
    saveExistManyGroupMan, // 根据项目
    reqOutperNameList, // 根据人员姓名
} from '../../request/api'

// 状态
const state = {
    allList: [],
    search: [],
    list: [],
    allPer: [], // 所有人 
    total: 0,
    limit: 10,
    page: 1,
    key: "",
    loading: false,
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
        state.loading = false
    },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
        state.loading = true
    },
    getAllList(state, arr) {
        state.allList = arr
    },
    changeSeaAll(state, arr) {
        state.allPer = arr
    },
    changeSearch(state, key) { // 将对比后的数组列表存放在search中
        state.search = [];
        state.key = key;
        state.page = 1;
        state.loading = true
    }
}
// 异步和逻辑操作
const actions = {
    reqAllList({
        commit
    }) {
        reqOutperL(JSON.parse(sessionStorage.getItem("user")).org[0].org_id).then(res => {
            commit('getAllList', res.data.data.items)
        })
    },
    reqList({
        commit,
        dispatch,
        state: {
            page,
            limit,
        }
    }) {
        reqOutperList(page, limit, JSON.parse(sessionStorage.getItem("user")).org[0].org_id).then(res => {
            const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
            list.forEach(i => {
                for (let j in i) {
                    if (j == 'per_identification_number') {
                        i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                    }
                }
            })
            commit('changeTotal', res.data.data.pageModel.total)
            if (list.length === 0 && page !== 1) {
                commit('changePage', page - 1)
                dispatch('reqList')
                return
            }
            commit('changeList', list)
        })
    },
    reqTotal({
        commit,
        dispatch,
        state: {
            page,
            limit,
            search
        }
    }) {
        reqOutperList(page, limit, JSON.parse(sessionStorage.getItem("user")).org[0].org_id).then(res => {
            commit('changeTotal', res.data.data.pageModel.total)
        })
    },
    changePage({
        commit,
        dispatch,
        state: {
            key
        }
    }, num) {
        // num当前页码值 page=num
        if (key !== "") {
            commit('changePage', num)
            var reg_ide = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/;
            console.log(key, 'shuchu');
            if (reg_ide.test(key.keywords)) {
                dispatch('changeSearchPro', key)
            } else {
                dispatch('changeSearchName', key)
            }
        } else {
            commit('changePage', num)
            // 发请求
            dispatch('reqList')
        }
    },
    // 搜索框(姓名) 都用这个
    changeSearch({
        commit,
        dispatch,
    }, key) {
        if (key) {
            var reg_ide = /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/
            const keywords = key.keywords
            commit('changeSearch', key)
            if (reg_ide.test(keywords)) {
                dispatch('changeSearchPro', key)
            } else {
                dispatch('changeSearchName', key)
            }
        } else {
            commit('changeSearch', '')
        }
    },
    // 搜索框(姓名) 都用这个
    changeSearchName({
        commit,
        dispatch,
        state: {
            page,
            limit,
        }
    }, key) {
        if (key) { // 关键字存在时做以下操作
            reqOutperNameList(page,
                limit,
                JSON.parse(sessionStorage.getItem("user")).org[0].org_id,
                key.keywords, // 姓名
                key.keyPro, // 项目
                key.banName, // 班组
                key.tof, // 工种
                key.phone, // 手机号
                key.companyId
            ).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
            })
        } else {
            // 重新请求列表及总数
            dispatch('reqList')
        }
    },
    // 搜索身份证
    changeSearchPro({
        commit,
        dispatch,
        state: {
            page,
            limit,
        }
    }, key) {
        if (key) {
            saveExistManyGroupMan(
                page,
                limit,
                JSON.parse(sessionStorage.getItem("user")).org[0].org_id,
                key.keywords, // 身份证号
                key.keyPro,
                key.banName,
                key.tof,
                key.phone,
                key.companyId
            ).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                list.forEach(i => {
                    for (let j in i) {
                        if (j == 'per_identification_number') {
                            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
                        }
                    }
                })
                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
            })
        } else {
            // 重新请求列表及总数
            dispatch('reqList')
        }
    },
    reqAllPer({
        commit,
        state: {
            page,
            limit,
            search
        }
    }) {
        if (search.length == 0) {
            reqOutperList(page, limit, 1).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqAllPer')
                    return
                }
                commit('changeSeaAll', list)
            })
        } else {
            page--;
            let a = search.slice(page * state.limit, (page * state.limit) + state.limit);
            commit('changeList', a)
        }
    },
    changeSeaPer({
        commit,
        dispatch,
        state: {
            page,
            limit,
        }
    }, key) {
        if (key) { // 关键字存在时做以下操作
            console.log(key, "key");
            commit('changePage', 1)
            reqOutperNameList(page, limit, 1, key).then(res => {
                const list = res.data.data.pageModel.records ? res.data.data.pageModel.records : []
                commit('changeTotal', res.data.data.pageModel.total)
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqAllPer')
                    return
                }
                commit('changeSeaAll', list)
            })
        } else {
            // 重新请求列表及总数
            dispatch('reqAllPer')
        }
    },
}
// 导出数据
const getters = {
    allList(state) {
        return state.allList
    },
    list(state) {
        return state.list
    },
    loading(state) {
        return state.loading
    },
    allPer(state) {
        return state.allPer
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.limit
    },
    page(state) {
        return state.page
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}