import {
    errorAlert
} from '@/utils/alert'
import {
    reqOutcompList,
    reqOutcompLss
} from '../../request/api'
// 状态
const state = {
    // 全部数据
    allList: [],
    // 搜索框的值
    search: [],
    // 分页数据
    list: [],
    // 总个数
    total: 0,
    // 一页显示的个数
    limit: 10,
    // 当前页码值
    page: 1,
    loading: false,
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
        state.loading = false
    },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
        state.loading = true
    },
    getAllList(state, arr) {
        state.allList = arr
    },
    changeSearch(state, key) { // 将对比后的数组列表存放在search中
        state.loading = true
        state.search = [];
        state.page = 1
        if (key) { // 如果有关键字，则做以下操作
            state.allList.forEach(v => {
                if (v.name.includes(key)) { // 包含关键字（模糊查询）
                    state.search.push(v)
                }
            })
            state.list = state.search.slice(0, state.limit); // 将第一页展示10条信息
            state.total = state.search.length; // 做分页的总条数
            state.loading = false
        }
    }
}
// 异步和逻辑操作
const actions = {
    reqAllList({
        commit
    }) {
        let org = JSON.parse(sessionStorage.getItem("user")).org;
        let orgIdList = []
        org.map(item => {
            if (item.org_level == 1) {
                orgIdList.push(item.org_id);
            }
        });
        reqOutcompLss(orgIdList).then(res => {
            commit('getAllList', res.data.data.items)
        }).catch(error => {
            errorAlert("服务器请求错误");
        })
    },
    reqList({
        commit,
        dispatch,
        state: {
            page,
            limit,
            search
        }
    }) {
        if (search.length == 0) {
            let org = JSON.parse(sessionStorage.getItem("user")).org;
            let orgIdList = []
            org.map(item => {
                if (item.org_level == 1) {
                    orgIdList.push(item.org_id);
                }
            });
            reqOutcompList(page, limit, orgIdList).then(res => {
                const list = res.data.data.rows ? res.data.data.rows : []
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
                commit('changeTotal', res.data.data.total)
            }).catch(error => {
                errorAlert("服务器请求错误");
            })
        } else {
            page--;
            let a = search.slice(page * state.limit, (page * state.limit) + state.limit);
            commit('changeList', a)
        }
    },
    changePage({
        commit,
        dispatch
    }, num) {
        // num当前页码值 page=num
        commit('changePage', num)
        // 发请求
        dispatch('reqList')
    },
    // 搜索框
    changeSearch({
        commit,
        dispatch
    }, key) {
        if (key) {
            commit('changeSearch', key)
        } else {
            commit('changeSearch', key)
            dispatch('reqList')
        }
    }
}
// 导出数据
const getters = {
    list(state) {
        return state.list
    },
    loading(state) {
        return state.loading
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.limit
    },
    page(state) {
        return state.page
    },
    allList(state) {
        return state.allList
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}